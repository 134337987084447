<template>
  <div id="wrapper">
    <div id="main-ui-wrapper" style="padding-top: 0.5em; padding-bottom: 0.5em" class="container-fluid">
      <div id="flex-wrap"
        style="display: flex; gap: 0.5rem; flex-wrap: wrap; width: 100%; justify-content: center; margin-bottom: 0.5rem">
        <div class="main-panel border shadow-sm rounded" style="width:100%">
          <a href="#collapseIndicatorChevronDark" v-b-toggle.collapse-about @click.prevent class="text-center"
            style="display: block;  margin-bottom: 0.5rem;" data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="collapseIndicatorChevronDark">
            Instructions
            <b-icon-chevron-down class="when-closed" />
            <b-icon-chevron-up class="when-open" />
          </a>
          <b-collapse id="collapse-about" style="text-align: justify;">
            <p style="margin-bottom: 0.5rem;">
              Should take about 7 minutes to generate the longest possible snapshot.
            </p>
          </b-collapse>
          <hr style="margin-top: 0rem; margin-bottom: 0.5rem" />
          <div class="ace-wrapper">
            <div class="ace-editor" ref="ace"></div>
          </div>
          <b-button @click="generateLong" :variant="generating ? 'outline-success' : 'success'" size="lg"
            style="width: 100%; margin-top: 1rem" type=submit :disabled="generating">
            <div v-if="generating" class="load-spinner spinner-border spinner-border-md" role="status">
              <span class="sr-only">Generating...</span>
            </div>
            <div v-else>Generate</div>
          </b-button>
        </div>
      </div>
      <div class="main-panel panel-col border shadow-sm rounded"
        style="margin-bottom: 0.5rem; margin-left:auto; margin-right: auto;">
        <div style="display: flex; gap: 0.5rem; margin-bottom: 1rem;">
          <b-button variant="dark" @click="exportCsv" :disabled="!points">Export as CSV</b-button>
          <b-button variant="dark" @click="exportCsvPositions" :disabled="!points">Export positions CSV</b-button>
          <b-dropdown variant="dark" @click="exportJson" split text="Export raw JSON">
            <b-dropdown-item @click="importJson">Import raw JSON</b-dropdown-item>
          </b-dropdown>
          <b-button variant="outline-danger" @click="resetConfig" style="margin-left: auto;">Reset config</b-button>
        </div>
        <div id="points-table-wrapper" style="overflow-y: scroll; height: 300px">
          <b-skeleton-table v-if="points == null || points.userPoints == null" :animation="null" :rows="5" :columns="4"
            :table-props="{ bordered: false, striped: true }"></b-skeleton-table>
          <table v-else id="pointsTable">
            <thead style="position: sticky; top:0">
              <tr>
                <th>#</th>
                <th scope="col">Level</th>
                <th scope="col">Points</th>
                <th scope="col">Address</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tr v-for="(user) in points.userPoints.slice(0, 50000)">
              <td>{{ user.leaderboardRank }}</td>
              <td>{{ user._currentLevel }}</td>
              <td>{{ user.totalPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
              <td class="text-truncate" style="max-width: 0;">
                <a :href="'https://ambient.finance/' + user.userAddress">{{ user.userAddress }}</a>
              </td>
              <td style="padding-left: 1rem"></td>
            </tr>
          </table>
        </div>
        <div v-if="points != null && points.userPoints != null" style="margin-top: 1rem;">
          <p>Total points: <b>{{ points.totalPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</b>. Total
            users:
            <b>{{ points.userPoints.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} </b> {{
            points.userPoints.length > 50000 ? "(Only first 50000 are shown)" : "" }}
          </p>
          <!-- <div style="margin-bottom:0.35rem">Users per level:</div>
          <div style="overflow-x: auto;">
            <table id="pointsTable" style="margin-bottom: 1rem;">
              <thead>
                <tr>
                  <th style="width: 20%;"><b>Level</b></th>
                  <th v-for="(level) in Object.keys(points.usersPerLevel)" style="padding-left: 0.38rem">{{ level }}
                  </th>
                </tr>
              </thead>

              <tr>
                <td><b>Users</b></td>
                <td v-for="(users) in Object.values(points.usersPerLevel)" style="padding-left: 0.38rem">{{
            users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
              </tr>
            </table>
          </div> -->
          <br />
          <div style="margin-bottom:0.35rem">Points per chain (approx.):</div>
          <div style="overflow-x: auto;">
            <table id="pointsTable" style="margin-bottom: 1rem;">
              <thead>
                <tr>
                  <th style="width: 20%;"><b>Chain</b></th>
                  <th v-for="(chain) in Object.keys(points.pointsPerChain)" style="padding-left: 0.35rem">{{
            chainName(chain) }}</th>
                </tr>
              </thead>

              <tr>
                <td><b>Points</b></td>
                <td v-for="(points) in Object.values(points.pointsPerChain)" style="padding-left: 0.35rem">{{
            points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                </td>
              </tr>
            </table>
          </div>
          <div style="margin-bottom:0.35rem">Users per chain (approx.):</div>
          <div style="overflow-x: auto;">
            <table id="pointsTable" style="margin-bottom: 1rem;">
              <thead>
                <tr>
                  <th style="width: 20%;"><b>Chain</b></th>
                  <th v-for="(chain) in Object.keys(points.usersPerChain)" style="padding-left: 0.35rem">{{
            chainName(chain) }}</th>
                </tr>
              </thead>

              <tr>
                <td><b>Points</b></td>
                <td v-for="(users) in Object.values(points.usersPerChain)" style="padding-left: 0.35rem">{{
            users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                </td>
              </tr>
            </table>
          </div>
          <div style="margin-bottom:0.35rem">Points per user per chain (approx.):</div>
          <div style="overflow-x: auto;">
            <table id="pointsTable" style="margin-bottom: 1rem;">
              <thead>
                <tr>
                  <th style="width: 20%;"><b>Chain</b></th>
                  <th v-for="(chain) in Object.keys(points.pointsPerUserPerChain)" style="padding-left: 0.35rem">{{
            chainName(chain) }}</th>
                </tr>
              </thead>

              <tr>
                <td><b>Points/user/chain</b></td>
                <td v-for="(ppupc) in Object.values(points.pointsPerUserPerChain)" style="padding-left: 0.35rem">{{
            Math.round(ppupc).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                </td>
              </tr>
            </table>
          </div>
          <div v-if="points.swapStatsApproved">
            <hr />
            <br />
            <div style="margin-bottom:0.35rem">Swap statistics (bots excluded)</div>
            <div style="overflow-x: auto;">
              <table id="swapStatistics1" style="margin-bottom: 1rem;">
                <thead>
                  <tr>
                    <th><b>Dataset</b></th>
                    <th style="padding-left: 0.47rem"><b>Volume</b></th>
                    <th style="padding-left: 0.47rem"><b>Mean</b></th>
                    <th style="padding-left: 0.47rem"><b>Median</b></th>
                    <th style="padding-left: 0.47rem"><b>StDev</b></th>
                    <!-- <th style="padding-left: 0.47rem"><b>Variance</b></th> -->
                    <th style="padding-left: 0.47rem"><b>CoefVar</b></th>
                    <th style="padding-left: 0.47rem"><b>Q1</b></th>
                    <th style="padding-left: 0.47rem"><b>Q3</b></th>
                    <th style="padding-left: 0.47rem"><b>IQR</b></th>
                    <th style="padding-left: 0.47rem"><b>Min</b></th>
                    <th style="padding-left: 0.47rem"><b>Max</b></th>
                  </tr>
                </thead>

                <tr>
                  <td><b>All swaps</b></td>
                  <td style="padding-left: 0.47rem">${{
            Math.round(points.swapStatsApproved.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                  </td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.mean) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.median) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.stdDev) }}</td>
                  <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.swapStatsApproved.variance) }}</td> -->
                  <td style="padding-left: 0.47rem">{{ points.swapStatsApproved.cv.toFixed(2) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.q1) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.q3) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.iqr) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.min) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsApproved.max) }}</td>
                </tr>
                <tr>
                  <td><b>> min vol</b></td>
                  <td style="padding-left: 0.47rem">${{
            Math.round(points.swapStatsPointed.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                  </td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.mean) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.median) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.stdDev) }}</td>
                  <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.swapStatsPointed.variance) }}</td> -->
                  <td style="padding-left: 0.47rem">{{ points.swapStatsPointed.cv.toFixed(2) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.q1) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.q3) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.iqr) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.min) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.swapStatsPointed.max) }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div style="margin-bottom:0.35rem">Position statistics (latest positions, the positions that were active at
            last snapshot)</div>
          <div style="overflow-x: auto;">
            <table id="posStatistics1" style="margin-bottom: 1rem;">
              <thead>
                <tr>
                  <th><b>Dataset</b></th>
                  <!-- <th style="padding-left: 0.47rem"><b>Volume</b></th> -->
                  <th style="padding-left: 0.47rem"><b>Mean</b></th>
                  <th style="padding-left: 0.47rem"><b>Median</b></th>
                  <th style="padding-left: 0.47rem"><b>StDev</b></th>
                  <!-- <th style="padding-left: 0.47rem"><b>Variance</b></th> -->
                  <th style="padding-left: 0.47rem"><b>CoefVar</b></th>
                  <th style="padding-left: 0.47rem"><b>Q1</b></th>
                  <th style="padding-left: 0.47rem"><b>Q3</b></th>
                  <th style="padding-left: 0.47rem"><b>IQR</b></th>
                  <th style="padding-left: 0.47rem"><b>Min</b></th>
                  <th style="padding-left: 0.47rem"><b>Max</b></th>
                </tr>
              </thead>

              <tr>
                <td><b>All positions</b></td>
                <!-- <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td> -->
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.mean) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.median) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.stdDev) }}</td>
                <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.posStatsLatest.variance) }}</td> -->
                <td style="padding-left: 0.47rem">{{ points.posStatsLatest.cv.toFixed(2) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.q1) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.q3) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.iqr) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.min) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatest.max) }}</td>
              </tr>
              <tr>
                <td><b>> min size</b></td>
                <!-- <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td> -->
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.mean) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.median) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.stdDev) }}</td>
                <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.posStatsLatestPointed.variance) }}</td> -->
                <td style="padding-left: 0.47rem">{{ points.posStatsLatestPointed.cv.toFixed(2) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.q1) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.q3) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.iqr) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.min) }}</td>
                <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsLatestPointed.max) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="points.posStatsLatest">
            <div style="margin-bottom:0.35rem">Position statistics (all positions, size at first deposit)</div>
            <div style="overflow-x: auto;">
              <table id="posStatistics1" style="margin-bottom: 1rem;">
                <thead>
                  <tr>
                    <th><b>Dataset</b></th>
                    <!-- <th style="padding-left: 0.47rem"><b>Volume</b></th> -->
                    <th style="padding-left: 0.47rem"><b>Mean</b></th>
                    <th style="padding-left: 0.47rem"><b>Median</b></th>
                    <th style="padding-left: 0.47rem"><b>StDev</b></th>
                    <!-- <th style="padding-left: 0.47rem"><b>Variance</b></th> -->
                    <th style="padding-left: 0.47rem"><b>CoefVar</b></th>
                    <th style="padding-left: 0.47rem"><b>Q1</b></th>
                    <th style="padding-left: 0.47rem"><b>Q3</b></th>
                    <th style="padding-left: 0.47rem"><b>IQR</b></th>
                    <th style="padding-left: 0.47rem"><b>Min</b></th>
                    <th style="padding-left: 0.47rem"><b>Max</b></th>
                  </tr>
                </thead>

                <tr>
                  <td><b>All positions</b></td>
                  <!-- <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td> -->
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.mean) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.median) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.stdDev) }}</td>
                  <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.posStatsFirst.variance) }}</td> -->
                  <td style="padding-left: 0.47rem">{{ points.posStatsFirst.cv.toFixed(2) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.q1) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.q3) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.iqr) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.min) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirst.max) }}</td>
                </tr>
                <tr>
                  <td><b>> min size</b></td>
                  <!-- <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.totalVolume).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td> -->
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.mean) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.median) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.stdDev) }}</td>
                  <!-- <td style="padding-left: 0.47rem">{{ Math.round(points.posStatsFirstPointed.variance) }}</td> -->
                  <td style="padding-left: 0.47rem">{{ points.posStatsFirstPointed.cv.toFixed(2) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.q1) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.q3) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.iqr) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.min) }}</td>
                  <td style="padding-left: 0.47rem">${{ Math.round(points.posStatsFirstPointed.max) }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div v-if="points.pointsPerWeekPerPool">
            <hr />
            <br />
            <div style="margin-bottom:0.35rem">
              <h4>Points per week per pool (top 7 pools per chain)</h4>
            </div>
            <div v-for="(pools, week) in filteredPools" v-bind:key="week">
              Snapshot: <b>{{ new Date(week * 1000).toLocaleDateString() }}</b> —
              Liq: <b>{{ parseInt(weeklyPoolsStats.totals[week].liqPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g,
            ",") }}</b> pts, Swap: <b>{{
            parseInt(weeklyPoolsStats.totals[week].swapPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}</b> pts (<b>{{ parseInt(weeklyPoolsStats.totals[week].liqDomination) }}%</b> for liq)
              <div style="overflow-x: auto; padding-top: 1rem;">
                <table id="pointsPerWeekPerPool" style="margin-bottom: 1rem; width: 100%;">
                  <thead>
                    <tr>
                      <th><b>Pair</b></th>
                      <th style="padding-left: 0.47rem"><b>Chain</b></th>
                      <th style="padding-left: 0.47rem"><b>% of week</b></th>
                      <th style="padding-left: 0.47rem"><b>Liq Points</b></th>
                      <th style="padding-left: 0.47rem"><b>Swap Points</b></th>
                    </tr>
                  </thead>

                  <tr v-for="pool of pools" v-bind:key="pool.chainId + pool.pair">
                    <td><b>{{ pool.pair }}</b></td>
                    <td style="padding-left: 0.47rem">{{ chainName(pool.chainId) }}</td>
                    <td style="padding-left: 0.47rem">{{ pool.percentOfWeek.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,
            ",") }}</td>
                    <td style="padding-left: 0.47rem">{{
            parseInt(pool.liqPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                    <td style="padding-left: 0.47rem">{{
            parseInt(pool.swapPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                  </tr>
                </table>
              </div>
              <hr />
            </div>
            <div style="margin-bottom:0.35rem">
              <h4>Per pool totals</h4>
            </div>
            Total liq points: <b>{{ parseInt(perPoolTotals.total.liqPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g,
            ",") }}</b> pts, Total swap points: <b>{{
            parseInt(perPoolTotals.total.swapPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</b>
            pts (<b>{{ parseInt(perPoolTotals.total.liqDomination) }}%</b> for liq)
            <div style="overflow-x: auto; padding-top: 1rem;">
              <table id="pointsPerPool" style="margin-bottom: 1rem; width: 100%;">
                <thead>
                  <tr>
                    <th><b>Pair</b></th>
                    <th style="padding-left: 0.47rem"><b>Chain</b></th>
                    <th style="padding-left: 0.47rem"><b>% of total</b></th>
                    <th style="padding-left: 0.47rem"><b>Liq Points</b></th>
                    <th style="padding-left: 0.47rem"><b>Swap Points</b></th>
                  </tr>
                </thead>

                <tr v-for="pool of perPoolTotals.pools" v-bind:key="pool.chainId + pool.pair">
                  <td><b>{{ pool.pair }}</b></td>
                  <td style="padding-left: 0.47rem">{{ chainName(pool.chainId) }}</td>
                  <td style="padding-left: 0.47rem">{{ pool.percentOfTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,
                    ",") }}</td>
                  <td style="padding-left: 0.47rem">{{
                    parseInt(pool.liqPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                  <td style="padding-left: 0.47rem">{{
                    parseInt(pool.swapPoints).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                </tr>
              </table>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BButton,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BIconChevronDown,
  BIconChevronUp,
  BSkeletonTable
} from "bootstrap-vue";

// import * as ace from "./ace/ace";
// import 'ace-builds/webpack-resolver'
// import 'ace-builds/src-noconflict/theme-github_dark'
import './ace/theme-dracula'
import './ace/mode-json5'
import { confDefault } from './defaultConf.jsx'

import cloneDeep from 'lodash.clonedeep'

export default {
  name: "App",
  components: {
    BButton,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BIconChevronDown,
    BIconChevronUp,
    BSkeletonTable,
  },
  data: function () {
    return {
      aceEditor: null,
      generating: false,
      generatedAt: null,
      points: null,
      pointsUrl: "https://cdn.bus.bz/points/points",
      pointsLongUrl: "https://cdn.bus.bz/points/points_long",
      pointsResultUrl: "https://cdn.bus.bz/points/points_result",
      themePath: 'ace/theme/dracula',
      modePath: 'ace/mode/json5'
    };
  },
  methods: {
    resetConfig: function () {
      console.log("Resetting config")
      localStorage.removeItem("config_v18")
      this.aceEditor.setValue(cloneDeep(confDefault))
      this.aceEditor.selection.clearSelection()
    },
    generate: async function () {
      this.generating = true
      try {
        let config = this.aceEditor.getValue()
        const response = await fetch(this.pointsUrl, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: config,
        });
        let resp = await response.json()
        if (resp.error)
          throw resp.error
        this.points = resp
        this.generatedAt = new Date()
        // console.log('points resp', this.points)
      } catch (e) {
        console.error("Generation error", e)
        this.showToast("Generation error", String(e), "danger", true)
      }
      this.generating = false
    },
    generateLong: async function () {
      this.generating = true
      try {
        let config = this.aceEditor.getValue()
        let response = await fetch(this.pointsLongUrl, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: config,
        });
        let resp = await response.json()
        if (resp.error)
          throw resp.error

        await new Promise(r => setTimeout(r, 1000));

        let reqId = resp.requestId
        while (true) {
          try {
            let response = await fetch(this.pointsResultUrl + "?requestId=" + reqId, {
              method: "GET",
              mode: "cors",
              redirect: "follow",
              referrerPolicy: "no-referrer",
            });
            resp = await response.json()
            if (resp.status == "pending") {
              await new Promise(r => setTimeout(r, 2000));
              continue
            }
            if (resp.error || resp == undefined) {
              console.log('points result error', resp.error)
              throw resp.error
            }
            this.points = null
            this.$nextTick(() => {
              this.points = resp
            })
            break
          } catch (e) {
            console.error("fetch points result error", e)
            if (resp.error)
              throw resp.error
            if (!resp)
              throw "No response"
            await new Promise(r => setTimeout(r, 2000));
          }
        }
        // this.points = resp
        this.generatedAt = new Date()
        // console.log('points resp', this.points)
      } catch (e) {
        console.error("Generation error", e)
        this.showToast("Generation error", String(e), "danger", true)
      }
      this.generating = false
    },
    exportJson: function () {
      var a = document.createElement("a")
      a.href = URL.createObjectURL(
        new Blob([JSON.stringify(this.points, null, 2)], { type: "application/json" })
      )
      a.download = `points_${this.generatedAt.toISOString().replaceAll(":", "-")}.json`
      a.click()

      // const filename = 'data.json';
      // const jsonStr = JSON.stringify(JsonExport);

      // let element = document.createElement('a');
      // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
      // element.setAttribute('download', filename);

      // element.style.display = 'none';
      // document.body.appendChild(element);

      // element.click();

      // document.body.removeChild(element);
    },
    importJson: function () {
      var input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = readerEvent => {
          this.points = null;
          var content = readerEvent.target.result;
          this.$nextTick(() => {
            this.points = JSON.parse(content);
            this.generatedAt = new Date();
          });
        }
      }
      input.click();
    },
    exportCsv: function () {
      let csv = ""
      const columns = ["rank", "level", "points", "posSizeSum", "posCount", "swapVol", "swapCount", "firstInteraction", "used_eth", "used_scroll", "used_canto", "used_blast", "address"]
      for (let i = 0; i < columns.length; i++) {
        csv += columns[i] + (i + 1 == columns.length ? '\n' : ',')
      }
      for (const user of this.points.userPoints) {
        const used_eth = user.chainPoints["0x1"] > 0
        const used_scroll = user.chainPoints["0x82750"] > 0
        const used_blast = user.chainPoints["0x13e31"] > 0
        const used_canto = user.chainPoints["0x1e14"] > 0
        csv += `${user.leaderboardRank},${user._currentLevel},${user.totalPoints},${user.posSizeSum || 0},${user.posCount || 0},${user.swapVol || 0},${user.swapCount || 0},${user.firstInteraction},${used_eth},${used_scroll},${used_canto},${used_blast},${user.userAddress}\n`
      }
      var a = document.createElement("a")
      a.href = URL.createObjectURL(
        new Blob([csv], { type: "text/csv" })
      )
      a.download = `points_${this.generatedAt.toISOString().replaceAll(":", "-")}.csv`
      a.click()
    },
    exportCsvPositions: function () {
      let csv = ""
      const columns = ["pair", "chainId", "usdValue", "widthPercent", "bidTick", "askTick", "mintTime", "burnTime", "user", "posId"]
      for (let i = 0; i < columns.length; i++) {
        csv += columns[i] + (i + 1 == columns.length ? '\n' : ',')
      }
      for (const [posId, pos] of Object.entries(this.points.posStats || {})) {
        csv += `${pos.pair},${pos.chainId},${pos.usdValue},${pos.widthPercent},${pos.bidTick},${pos.askTick},${pos.mintTime},${pos.burnTime},${pos.user},${posId}\n`
      }
      var a = document.createElement("a")
      a.href = URL.createObjectURL(
        new Blob([csv], { type: "text/csv" })
      )
      a.download = `posStats_${this.generatedAt.toISOString().replaceAll(":", "-")}.csv`
      a.click()
    },
    showToast: function (title, text, variant = "danger", noAutoHide = false) {
      this.$bvToast.toast(text, {
        title: title,
        toaster: "b-toaster-top-center",
        solid: false,
        appendToast: true,
        variant: variant,
        autoHideDelay: 5000,
        noAutoHide,
      });
    },
    chainName: function (chainId) {
      const chains = {
        "0x1": "Ethereum",
        "0x1e14": "Canto",
        "0x13e31": "Blast",
        "0x82750": "Scroll",
      }
      let chainName = chains[chainId]
      if (!chainName)
        chainName = chainId
      return chainName
    }
  },
  watch: {
    // address: function (address) {
    //   console.log('got address', address)
    //   if (address) {
    //     this.resetConfig(false, true)
    //     this.refreshData(address)
    //   } else {
    //     this.resetConfig(false, true)
    //   }
    // },
  },
  computed: {
    filteredPools: function () {
      const TOP_POOLS_PER_CHAIN = 7
      const weeklyPools = this.points.pointsPerWeekPerPool
      const filteredWeeklyPools = {}
      const weekTotals = {}
      for (const [week, pools] of Object.entries(weeklyPools)) {
        for (const pool of pools) {
          weekTotals[week] = weekTotals[week] || 0
          weekTotals[week] += pool.liqPoints + pool.swapPoints
        }
      }
      for (const [week, pools] of Object.entries(weeklyPools)) {
        const chainPoolCount = {}
        for (const pool of pools) {
          if (!chainPoolCount[pool.chainId]) chainPoolCount[pool.chainId] = 0;
          chainPoolCount[pool.chainId]++;
          filteredWeeklyPools[week] = filteredWeeklyPools[week] || [];
          pool.percentOfWeek = (pool.liqPoints + pool.swapPoints) / weekTotals[week] * 100
          if (chainPoolCount[pool.chainId] <= TOP_POOLS_PER_CHAIN)
            filteredWeeklyPools[week].push(pool)
        }
      }
      console.log(filteredWeeklyPools)
      return filteredWeeklyPools;
    },
    perPoolTotals: function () {
      const pools = {}
      const chains = {}
      const total = {
        liqPoints: 0,
        swapPoints: 0,
        liqDomination: 0,
      }

      //const TOP_POOLS = 20
      for (const weeks of Object.values(this.points.pointsPerWeekPerPool)) {
        for (const pool of Object.values(weeks)) {
          const poolKey = pool.chainId + "_" + pool.pair
            pools[poolKey] = pools[poolKey] || {
              pair: pool.pair,
              chainId: pool.chainId,
              liqPoints: 0,
              swapPoints: 0,
              liqDomination: 0,
            }
          //}
          if (pools[poolKey]) {
            pools[poolKey].liqPoints += pool.liqPoints
            pools[poolKey].swapPoints += pool.swapPoints
          }
          chains[pool.chainId] = chains[pool.chainId] || {
            liqPoints: 0,
            swapPoints: 0,
            liqDomination: 0,
          }
          chains[pool.chainId].liqPoints += pool.liqPoints
          chains[pool.chainId].swapPoints += pool.swapPoints
          total.liqPoints += pool.liqPoints
          total.swapPoints += pool.swapPoints
        }
      }

      for (const pool of Object.values(pools)) {
        pool.liqDomination = 100 - pool.swapPoints / pool.liqPoints * 100
        pool.percentOfTotal = (pool.liqPoints + pool.swapPoints) / (total.liqPoints + total.swapPoints) * 100
      }
      const poolArray = Object.values(pools)
      poolArray.sort((a, b) => b.liqPoints + b.swapPoints - a.liqPoints - a.swapPoints)
      total.liqDomination = 100 - total.swapPoints / total.liqPoints * 100
      return { pools: poolArray, chains, total }
    },
    weeklyPoolsStats: function () {
      const totalsPerWeek = {}
      for (const [week, pools] of Object.entries(this.points.pointsPerWeekPerPool)) {
        totalsPerWeek[week] = {
          liqPoints: 0,
          swapPoints: 0,
          liqDomination: 0,
        }
        for (const pool of pools) {
          totalsPerWeek[week].liqPoints += pool.liqPoints
          totalsPerWeek[week].swapPoints += pool.swapPoints
        }
      }
      for (const [week, pools] of Object.entries(this.points.pointsPerWeekPerPool)) {
        totalsPerWeek[week].liqDomination = 100 - totalsPerWeek[week].swapPoints / totalsPerWeek[week].liqPoints * 100
      }
      console.log(totalsPerWeek)
      return { totals: totalsPerWeek };
    }
  },
  mounted: function () {
    this.aceEditor = ace.edit(this.$refs.ace, {
      maxLines: 30,
      minLines: 10,
      fontSize: 12,
      theme: this.themePath,
      mode: this.modePath,
      tabSize: 2,
      wrap: "free",
      wrapMethod: "code",
      indentedSoftWrap: true,
      printMargin: false,
    })
    const conf = localStorage.getItem("config_v18")
    console.log('conf', conf)
    if (!conf || conf == "null" || conf == "undefined") {
      localStorage.removeItem("config_v18")
      this.resetConfig()
    } else {
      console.log("Loading stored config")
      this.aceEditor.setValue(cloneDeep(conf))
      this.aceEditor.selection.clearSelection()
    }
    // watchAccount((account) => this.accountChanged(account))
    // watchNetwork((network) => this.networkChanged(network))
    if (this.refreshTicker == null)
      this.refreshTicker = setInterval(() => {
        localStorage.setItem("config_v18", this.aceEditor.getValue())
      }, 3000);
    // document.addEventListener('visibilitychange', this.onVisibilityChange)
    // this.fetchTokens(mainnet.id)
  },
  unmounted: function () {
    if (this.refreshTicker) {
      clearInterval(this.refreshTicker)
      this.refreshTicker = null;
    }
    localStorage.setItem("config_v18", this.aceEditor.getValue())
    // document.removeEventListener('visibilitychange', this.onVisibilityChange);
  },
};
</script>

<style lang="scss">
@import 'node_modules/@forevolve/bootstrap-dark/scss/bootstrap-dark.scss';

$bg: #0d1117;

body,
html {
  width: 100%;
  height: 100%;
  background-color: $bg;
}

select,
input,
button.input-like {
  background-color: darken($bg, 1%) !important;
}

a {
  color: darken($blue, 0%);
}

a:hover {
  color: darken($blue, 15%);
}

w3m-balance {
  margin-right: auto;
}

* {
  font-family: Helvetica;
  box-sizing: border-box;
}

#wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

#main-ui-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
}

.main-panel {
  padding: 1rem 1rem 1em 1em;
  background-color: lighten($bg, 3%);
  max-width: 46rem;
}

.panel-col {
  margin-bottom: 0.5rem;
}

#logo {
  width: 100%;
}

#footer {
  font-size: 0.8rem;
  color: $gray-600;
}

#footer a {
  color: darken($gray-600, 15%);
}

.load-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

/* Define the animation keyframes */
@keyframes loading-underline {
  0% {
    left: 0;
    width: 0;
  }

  50% {
    left: 0;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 0;
  }
}

.animated-underline {
  position: relative;
  padding-bottom: 0.5em;
}

.animated-underline::after {
  content: "";
  position: absolute;
  margin-top: 0.5em;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6c757d;
  animation: loading-underline 1s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}

#b-header-dropdown button {
  color: inherit !important;
}


.icon-spin {
  animation: spin-animation 1s infinite;
  animation-timing-function: linear;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

#w3-button-container {
  text-align: center;
  outline: 0;
}

#w3-connected {
  background-color: darken($bg, 1%);
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  outline: none;
  cursor: default;
}

#w3-address-pill {
  margin: 0.1rem 0 0.1rem 0.3rem;
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
  padding: 0.3rem 0.5rem 0.3rem 0.3rem;
  font-family: monospace;
  overflow: hidden;
  text-overflow: ellipsis;
}

.with-avatar {
  padding-left: 0.2rem !important;
}

#pointsTable {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#pointsTable th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  background-color: lighten($bg, 5%);
}
</style>
