export const confDefault = `[{
  // WARNING: If you need to change core parameters of the config after snapshots already went public, set this parameter to the week when the next snapshot should become active and create another complete config object below in this array. Configs get switched once a week.
  "configActiveUntil": "2024-03-20T12:00:00Z",

  // startTime sets the beginning of data collection, and the first snapshot will be taken a week after startTime. It should be set to the start of the retroactive snapshot period.
  // Times should have zero minutes and seconds.
  "startTime": "2023-05-31T12:00:00Z",
  // endTime isn't needed anymore in prod because retroactiveDistroWeeks exists. Not setting it means a snapshot will be generated every week indefinitely, the first one at the end of the current week (not the last week).
  // "endTime": "2024-05-01T12:00:00Z",

  // This sets the week when the retroactive snapshot is taken. If this date is set, then the points history will be restarted on this day, and all the points earned since startTime before this week will be redistributed for retroactiveDistroWeeks number of weeks (including the one when the retroactive snapshot was taken).
  // This should be set to the date a week before the points system is meant to go live.
  "retroactiveSnapshotTime": "2024-02-21T12:00:00Z",
  // Nubmer of weeks after which the retroactive points will be fully distributed. All weeks past this points will have zero retroactive points dropped.
  "retroactiveDistroWeeks": 6,

  // Each point is worth this USD amount of active liquidity provided per day by a user.
  "dailyLiqUsdValuePerPoint": "100",

  // Each swap point is worth this amount of USD volume swapped in a day by a user.
  "dailySwapUsdVolumePerPoint": "250",

  // Discard daily volume and liquidty under this USD value.
  "minUsdValueThreshold": "5",

  // Multiply all points by this number
  "feelGoodMultiplier": "10.0",

  // Multiply all retroactive points by this number.
  "retroactiveMultiplier": "1.0",

  // If a user didn't earn any points during a week, multiply their retroactive points for that week by this number.
  "retroactiveIdleMultiplier": "1.0",

  // Multiplies the size of a filled limit order before dividing it by dailySwapUsdVolumePerPoint to get the number of points.
  "filledLimitMultiplier": "2.0",

  // If a swap was made during a week when a user had no active positions, multiply its value by this number.
  // Useful for filtering non-atomic arbitrage bots (atomic ones are filtered out completely).
  "idleSwapMultiplier": "1.0",

  // If a position's range is tight, multiply its USD value by this number.
  "tightUsdValueBonus": "1.15",

  // Range width that's considered tight.
  "tightPriceRangePercent": "10",

  // All positions' USD values on a given chain until a given time will be multiplied by the specified multiplier.
  // Several multipliers per chain are possible, e.g. it's possible to specify a permanent multiplier for Ethereum and also give all chains a temporary multiplier before the points system went live.
  "chainMultipliers": [
    // +10% multiplier for Ethereum before bump to +25%
    {"chainId": "0x1", "multiplier": "1.1", "until": "2024-04-03T12:00:00Z"},
    // +25% multiplier for Ethereum
    {"chainId": "0x1", "multiplier": "1.25", "from": "2024-04-03T12:00:00Z", "until": "2222-01-01T00:00:00Z"},
    // -35% multiplier for Blast
    {"chainId": "0x13e31", "multiplier": "0.65", "from": "2024-04-03T12:00:00Z", "until": "2024-04-17T12:00:00Z"},
    // -60% multiplier for Blast
    {"chainId": "0x13e31", "multiplier": "0.4", "from": "2024-04-17T12:00:00Z", "until": "2222-01-01T00:00:00Z"},
  ],

  // Levels formula parameters. Not used by the frontend, just for testing.
  "levelsBase": 250,
  "levelsExp": 1.5,

  // Positions in these pools will have USD values multiplied.
  // Optionally each multiplier can have "from" and "until" timestamps supplied. Without them, it's assumed it's always active.
  "poolMultipliers": [
    // USDC/USDT Ethereum before points system goes live:
    {"multiplier": "0.25", "base": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "quote": "0xdac17f958d2ee523a2206206994597c13d831ec7", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // USDC/USDT Ethereum after points system goes live:
    {"multiplier": "0.1", "base": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "quote": "0xdac17f958d2ee523a2206206994597c13d831ec7", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // DAI/USDC Ethereum before live:
    {"multiplier": "0.25", "base": "0x6b175474e89094c44da98b954eedeac495271d0f", "quote": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // DAI/USDC Ethereum:
    {"multiplier": "0.1", "base": "0x6b175474e89094c44da98b954eedeac495271d0f", "quote": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // LUSD/DAI Ethereum:
    {"multiplier": "0.1", "base": "0x5f98805a4e8be255a32880fdec7f6728c6568ba0", "quote": "0x6b175474e89094c44da98b954eedeac495271d0f", "poolIdx": 420, "chainId": "0x1", "from": "2024-04-17T12:00:00Z"},

    // cbETH/rETH Ethereum before live:
    {"multiplier": "0.25", "base": "0xae78736cd615f374d3085123a210448e74fc6393", "quote": "0xbe9895146f7af43049ca1c1ae358b0541ea49704", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // cbETH/rETH Ethereum after live:
    {"multiplier": "0.1", "base": "0xae78736cd615f374d3085123a210448e74fc6393", "quote": "0xbe9895146f7af43049ca1c1ae358b0541ea49704", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // ETH/swETH Ethereum before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf951e335afb289353dc249e82926178eac7ded78", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // ETH/swETH Ethereum after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf951e335afb289353dc249e82926178eac7ded78", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // USDC/USDT Scroll before live:
    {"multiplier": "0.25", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // USDC/USDT Scroll after live:
    {"multiplier": "0.1", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // USDC/DAI Scroll before live:
    {"multiplier": "0.25", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // USDC/DAI Scroll after live:
    {"multiplier": "0.1", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // DAI/USDT Scroll before live:
    {"multiplier": "0.25", "base": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // DAI/USDT Scroll after live:
    {"multiplier": "0.1", "base": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // ETH/wstETH Scroll before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // ETH/wstETH Scroll after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z", "until": "2024-03-26T18:18:04Z"},
    // ETH/wstETH Scroll after Lido:
    {"multiplier": "0.3", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-03-26T18:18:04Z"},

    // ETH/RETH Scroll before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0x53878b874283351d26d206fa512aece1bef6c0dd", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // ETH/RETH Scroll after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x53878b874283351d26d206fa512aece1bef6c0dd", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // ETH/pxETH Scroll:
    {"multiplier": "0.6", "base": "0x0000000000000000000000000000000000000000", "quote": "0x9e0d7d79735e1c63333128149c7b616a0dc0bbdb", "poolIdx": 420, "chainId": "0x82750", "from": "2024-03-06T12:00:00Z"},
    // ETH/pxETH Ethereum:
    {"multiplier": "0.6", "base": "0x0000000000000000000000000000000000000000", "quote": "0x04c154b66cb340f3ae24111cc767e0184ed00cc6", "poolIdx": 420, "chainId": "0x1", "from": "2024-03-06T12:00:00Z"},

    // ETH/ezETH Blast:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x2416092f143378750bb29b79ed961ab195cceea5", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-03-27T12:00:00Z"},

    // ETH/wrsETH Scroll:
    {"multiplier": "0.2", "base": "0x0000000000000000000000000000000000000000", "quote": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-03T12:00:00Z"},

    // ETH/uniETH Scroll:
    {"multiplier": "0.3", "base": "0x0000000000000000000000000000000000000000", "quote": "0x15eefe5b297136b8712291b632404b66a8ef4d25", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // ETH/STONE Scroll:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x80137510979822322193fc997d400d5a6c747bf7", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // STONE/wstETH Scroll:
    {"multiplier": "0.1", "base": "0x80137510979822322193fc997d400d5a6c747bf7", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // STONE/wrsETH Scroll:
    {"multiplier": "0.1", "base": "0x80137510979822322193fc997d400d5a6c747bf7", "quote": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // wrsETH/wstETH Scroll:
    {"multiplier": "0.25", "base": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-03T12:00:00Z"},

    // ETH/wrsETH Blast:
    {"multiplier": "0.2", "base": "0x0000000000000000000000000000000000000000", "quote": "0xe7903b1f75c534dd8159b313d92cdcfbc62cb3cd", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

    // USDB/USDB+ Blast:
    {"multiplier": "0.1", "base": "0x4300000000000000000000000000000000000003", "quote": "0x4fee793d435c6d2c10c135983bb9d6d4fc7b9bbd", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

    // USDB/bZUSD Blast:
    {"multiplier": "0.1", "base": "0x4300000000000000000000000000000000000003", "quote": "0xdc1903c9579d6c1d523f4192508548b063d8a215", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

    // USDC/cNOTE Canto:
    {"multiplier": "0.35", "base": "0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd", "quote": "0xee602429ef7ece0a13e4ffe8dbc16e101049504c", "poolIdx": 36000, "chainId": "0x1e14"},
    // NOTE/USDC Canto:
    {"multiplier": "0.35", "base": "0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503", "quote": "0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd", "poolIdx": 36000, "chainId": "0x1e14"},
  ],

  // These chains will be ignored.
  "ignoreChains": [
    // "0x1",     // - Ethereum
    // "0x82750", // - Scroll
    // "0x1e14",  // - Canto
    // "0x13e31", // - Blast
  ],

  // All pools with these token addresses will be ignored.
  "ignorePositionsWithTokens": [],

  // In case of errors, this could be used to override points for a single user on a single week.
  "corrections": [
    // {"user": "0x0000000000000000000000000000000000000000", "week": "2024-01-01T00:00:00Z", "points": 0}
  ],

  // Greatly speeds up data ingress at the cost of some accuracy (~0.5% total point count difference, but individual effect can be substantial, especially on tight positions).
  // It's an acceptable sacrifice if precicse data isn't needed or impractical to get.
  "disableAllChainPositionFetching": true,

  // Skip all statistics calculation steps.
  "disableStatsCalculation": false,

  // Fetch liquidity position every this number of hours and average it over each day. Should be 1 for prod but it's very slow, set to 12 or 24 for faster snapshots. (although the first snapshot with a different period will still take some time)
  "liqCheckPeriodHours": 12,

  // Used in prod to make snapshot generation automatic. If set to true the end time is truncated to the last week, not current week.
  "endlessGeneration": false,
},
{
  // WARNING: If you need to change core parameters of the config after snapshots already went public, set this parameter to the week when the next snapshot should become active and create another complete config object below in this array. Configs get switched once a week.
  // "configActiveUntil": "2023-06-30T00:00:00Z",

  // startTime sets the beginning of data collection, and the first snapshot will be taken a week after startTime. It should be set to the start of the retroactive snapshot period.
  // Times should have zero minutes and seconds.
  "startTime": "2023-05-31T12:00:00Z",
  // endTime isn't needed anymore in prod because retroactiveDistroWeeks exists. Not setting it means a snapshot will be generated every week indefinitely, the first one at the end of the current week (not the last week).
  // "endTime": "2024-05-01T12:00:00Z",

  // This sets the week when the retroactive snapshot is taken. If this date is set, then the points history will be restarted on this day, and all the points earned since startTime before this week will be redistributed for retroactiveDistroWeeks number of weeks (including the one when the retroactive snapshot was taken).
  // This should be set to the date a week before the points system is meant to go live.
  "retroactiveSnapshotTime": "2024-02-21T12:00:00Z",
  // Nubmer of weeks after which the retroactive points will be fully distributed. All weeks past this points will have zero retroactive points dropped.
  "retroactiveDistroWeeks": 6,

  // Each point is worth this USD amount of active liquidity provided per day by a user.
  "dailyLiqUsdValuePerPoint": "100",

  // Each swap point is worth this amount of USD volume swapped in a day by a user.
  "dailySwapUsdVolumePerPoint": "500",

  // Discard daily volume and liquidty under this USD value.
  "minUsdValueThreshold": "5",

  // Multiply all points by this number
  "feelGoodMultiplier": "10.0",

  // Multiply all retroactive points by this number.
  "retroactiveMultiplier": "1.0",

  // If a user didn't earn any points during a week, multiply their retroactive points for that week by this number.
  "retroactiveIdleMultiplier": "1.0",

  // Multiplies the size of a filled limit order before dividing it by dailySwapUsdVolumePerPoint to get the number of points.
  "filledLimitMultiplier": "2.0",

  // If a swap was made during a week when a user had no active positions, multiply its value by this number.
  // Useful for filtering non-atomic arbitrage bots (atomic ones are filtered out completely).
  "idleSwapMultiplier": "1.0",

  // If a position's range is tight, multiply its USD value by this number.
  "tightUsdValueBonus": "1.15",

  // Range width that's considered tight.
  "tightPriceRangePercent": "10",

  // All positions' USD values on a given chain until a given time will be multiplied by the specified multiplier.
  // Several multipliers per chain are possible, e.g. it's possible to specify a permanent multiplier for Ethereum and also give all chains a temporary multiplier before the points system went live.
  "chainMultipliers": [
    // +10% multiplier for Ethereum before bump to +25%
    {"chainId": "0x1", "multiplier": "1.1", "until": "2024-04-03T12:00:00Z"},
    // +25% multiplier for Ethereum
    {"chainId": "0x1", "multiplier": "1.25", "from": "2024-04-03T12:00:00Z", "until": "2222-01-01T00:00:00Z"},
    // -35% multiplier for Blast
    {"chainId": "0x13e31", "multiplier": "0.65", "from": "2024-04-03T12:00:00Z", "until": "2024-04-17T12:00:00Z"},
    // -60% multiplier for Blast
    {"chainId": "0x13e31", "multiplier": "0.4", "from": "2024-04-17T12:00:00Z", "until": "2222-01-01T00:00:00Z"},
  ],

  // Levels formula parameters. Not used by the frontend, just for testing.
  "levelsBase": 250,
  "levelsExp": 1.5,

  // Positions in these pools will have USD values multiplied.
  // Optionally each multiplier can have "from" and "until" timestamps supplied. Without them, it's assumed it's always active.
  "poolMultipliers": [
    // USDC/USDT Ethereum before points system goes live:
    {"multiplier": "0.25", "base": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "quote": "0xdac17f958d2ee523a2206206994597c13d831ec7", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // USDC/USDT Ethereum after points system goes live:
    {"multiplier": "0.1", "base": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "quote": "0xdac17f958d2ee523a2206206994597c13d831ec7", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // DAI/USDC Ethereum before live:
    {"multiplier": "0.25", "base": "0x6b175474e89094c44da98b954eedeac495271d0f", "quote": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // DAI/USDC Ethereum:
    {"multiplier": "0.1", "base": "0x6b175474e89094c44da98b954eedeac495271d0f", "quote": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // LUSD/DAI Ethereum:
    {"multiplier": "0.1", "base": "0x5f98805a4e8be255a32880fdec7f6728c6568ba0", "quote": "0x6b175474e89094c44da98b954eedeac495271d0f", "poolIdx": 420, "chainId": "0x1", "from": "2024-04-17T12:00:00Z"},

    // cbETH/rETH Ethereum before live:
    {"multiplier": "0.25", "base": "0xae78736cd615f374d3085123a210448e74fc6393", "quote": "0xbe9895146f7af43049ca1c1ae358b0541ea49704", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // cbETH/rETH Ethereum after live:
    {"multiplier": "0.1", "base": "0xae78736cd615f374d3085123a210448e74fc6393", "quote": "0xbe9895146f7af43049ca1c1ae358b0541ea49704", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // ETH/swETH Ethereum before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf951e335afb289353dc249e82926178eac7ded78", "poolIdx": 420, "chainId": "0x1", "until": "2024-02-28T12:00:00Z"},
    // ETH/swETH Ethereum after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf951e335afb289353dc249e82926178eac7ded78", "poolIdx": 420, "chainId": "0x1", "from": "2024-02-28T12:00:00Z"},

    // USDC/USDT Scroll before live:
    {"multiplier": "0.25", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // USDC/USDT Scroll after live:
    {"multiplier": "0.1", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // USDC/DAI Scroll before live:
    {"multiplier": "0.25", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // USDC/DAI Scroll after live:
    {"multiplier": "0.1", "base": "0x06efdbff2a14a7c8e15944d1f4a48f9f95f663a4", "quote": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // DAI/USDT Scroll before live:
    {"multiplier": "0.25", "base": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // DAI/USDT Scroll after live:
    {"multiplier": "0.1", "base": "0xca77eb3fefe3725dc33bccb54edefc3d9f764f97", "quote": "0xf55bec9cafdbe8730f096aa55dad6d22d44099df", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // ETH/wstETH Scroll before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // ETH/wstETH Scroll after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z", "until": "2024-03-26T18:18:04Z"},
    // ETH/wstETH Scroll after Lido:
    {"multiplier": "0.3", "base": "0x0000000000000000000000000000000000000000", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-03-26T18:18:04Z"},

    // ETH/RETH Scroll before live:
    {"multiplier": "0.25", "base": "0x0000000000000000000000000000000000000000", "quote": "0x53878b874283351d26d206fa512aece1bef6c0dd", "poolIdx": 420, "chainId": "0x82750", "until": "2024-02-28T12:00:00Z"},
    // ETH/RETH Scroll after live:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x53878b874283351d26d206fa512aece1bef6c0dd", "poolIdx": 420, "chainId": "0x82750", "from": "2024-02-28T12:00:00Z"},

    // ETH/pxETH Scroll:
    {"multiplier": "0.6", "base": "0x0000000000000000000000000000000000000000", "quote": "0x9e0d7d79735e1c63333128149c7b616a0dc0bbdb", "poolIdx": 420, "chainId": "0x82750", "from": "2024-03-06T12:00:00Z"},
    // ETH/pxETH Ethereum:
    {"multiplier": "0.6", "base": "0x0000000000000000000000000000000000000000", "quote": "0x04c154b66cb340f3ae24111cc767e0184ed00cc6", "poolIdx": 420, "chainId": "0x1", "from": "2024-03-06T12:00:00Z"},

    // ETH/ezETH Blast:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x2416092f143378750bb29b79ed961ab195cceea5", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-03-27T12:00:00Z"},

    // ETH/wrsETH Scroll:
    {"multiplier": "0.2", "base": "0x0000000000000000000000000000000000000000", "quote": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-03T12:00:00Z"},

    // ETH/uniETH Scroll:
    {"multiplier": "0.3", "base": "0x0000000000000000000000000000000000000000", "quote": "0x15eefe5b297136b8712291b632404b66a8ef4d25", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // ETH/STONE Scroll:
    {"multiplier": "0.1", "base": "0x0000000000000000000000000000000000000000", "quote": "0x80137510979822322193fc997d400d5a6c747bf7", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // STONE/wstETH Scroll:
    {"multiplier": "0.1", "base": "0x80137510979822322193fc997d400d5a6c747bf7", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // STONE/wrsETH Scroll:
    {"multiplier": "0.1", "base": "0x80137510979822322193fc997d400d5a6c747bf7", "quote": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-24T12:00:00Z"},

    // wrsETH/wstETH Scroll:
    {"multiplier": "0.25", "base": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f", "quote": "0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32", "poolIdx": 420, "chainId": "0x82750", "from": "2024-04-03T12:00:00Z"},

    // ETH/wrsETH Blast:
    {"multiplier": "0.2", "base": "0x0000000000000000000000000000000000000000", "quote": "0xe7903b1f75c534dd8159b313d92cdcfbc62cb3cd", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

    // USDB/USDB+ Blast:
    {"multiplier": "0.1", "base": "0x4300000000000000000000000000000000000003", "quote": "0x4fee793d435c6d2c10c135983bb9d6d4fc7b9bbd", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

    // USDB/bZUSD Blast:
    {"multiplier": "0.1", "base": "0x4300000000000000000000000000000000000003", "quote": "0xdc1903c9579d6c1d523f4192508548b063d8a215", "poolIdx": 420, "chainId": "0x13e31", "from": "2024-04-10T12:00:00Z"},

     // USDC/cNOTE Canto:
    {"multiplier": "0.35", "base": "0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd", "quote": "0xee602429ef7ece0a13e4ffe8dbc16e101049504c", "poolIdx": 36000, "chainId": "0x1e14"},
     // NOTE/USDC Canto:
    {"multiplier": "0.35", "base": "0x4e71a2e537b7f9d9413d3991d37958c0b5e1e503", "quote": "0x80b5a32e4f032b2a058b4f29ec95eefeeb87adcd", "poolIdx": 36000, "chainId": "0x1e14"},
  ],

  // These chains will be ignored.
  "ignoreChains": [
    // "0x1",     // - Ethereum
    // "0x82750", // - Scroll
    // "0x1e14",  // - Canto
    // "0x13e31", // - Blast
  ],

  // All pools with these token addresses will be ignored.
  "ignorePositionsWithTokens": [],

  // In case of errors, this could be used to override points for a single user on a single week.
  "corrections": [
    // {"user": "0x0000000000000000000000000000000000000000", "week": "2024-01-01T00:00:00Z", "points": 0}
  ],

  // Greatly speeds up data ingress at the cost of some accuracy (~0.5% total point count difference, but individual effect can be substantial, especially on tight positions).
  // It's an acceptable sacrifice if precicse data isn't needed or impractical to get.
  "disableAllChainPositionFetching": true,

  // Skip all statistics calculation steps.
  "disableStatsCalculation": false,

  // Fetch liquidity position every this number of hours and average it over each day. Should be 1 for prod but it's very slow, set to 12 or 24 for faster snapshots. (although the first snapshot with a different period will still take some time)
  "liqCheckPeriodHours": 12,

  // Used in prod to make snapshot generation automatic. If set to true the end time is truncated to the last week, not current week.
  "endlessGeneration": false,
},
// Add another config object here if needed, don't forget to set configActiveUntil on the previous config!
]`
